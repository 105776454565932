.table-style {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  .table-style1 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table-container {
    overflow-y: auto;
    max-height: 600px; /* Adjust this value to fit your needs */
    border: 1px solid #ccc;
}

.duration-split {
  color: black; /* default color */
}

.duration-split.high-duration {
  color: red; /* color for durations over 20 minutes */
}


  .table-style th, .table-style td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  .table-style1 th, .table-style1 td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
    /* Updated styles for the header */
    .table-style1 th {
      background-color: #4CAF50; /* Darker shade for the header */
      color: white;
    }
    
    .table-style1 tr:nth-child(even) {
      background-color: #f9f9f9;
    }
    
    .table-style1 tr:hover {
      background-color: #e8e8e8;
    }
    
    .table-style1 td.red {
      color: red;
    }

  /* Updated styles for the header */
  .table-style th {
    background-color: #4CAF50; /* Darker shade for the header */
    color: white;
  }
  
  .table-style tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table-style tr:hover {
    background-color: #e8e8e8;
  }
  
  .table-style td.red {
    color: red;
  }

  .drawer {
    position: fixed;
    right: 0;
    top: 0;
    width: 700px; /* Adjust width as needed */
    height: 100vh;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .drawer.closed {
    transform: translateX(100%);
  }
  
  .widgets-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .widget {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    text-align: center;
  }
  
  .widget h3 {
    margin: 0 0 10px 0;
  }
  
  .widget p {
    margin: 0;
    font-size: 1em;
    font-weight: bold;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr .25fr; /* Defines two columns of equal width */
    gap: 10px; /* Space between grid items */
    padding: 10px; /* Padding around the grid container */
  }

  .grid-container1 {
    display: grid;
    grid-template-columns: 120px 120px 120px .25fr; /* Defines two columns of equal width */
    gap: 5px; /* Space between grid items */
    padding:5px; /* Padding around the grid container */
  }

  .grid-container2 {
    display: grid;
    grid-template-columns: 120px 120px 120px 120px 120px .25fr; /* Defines two columns of equal width */
    gap: 5px; /* Space between grid items */
    padding:5px; /* Padding around the grid container */
  }

  
  .search-input {
    width: 100%; /* Each input field will fill its grid column */
    padding: 8px; /* Padding inside the input fields for better text visibility */
  }

  .approved {
    color: green; /* Makes the background of approved cells green */
  }

  .rejected {
    color: red; /* Red background for rejected days */
  }

  
body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ensure the body covers the entire viewport height */
    margin: 0; /* Remove default margin */
  }
  
  .login-container {
    min-width: 500px;
    min-height: 500px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
  }
  
  .form-control {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 90%;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .login-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  
 
  .div1 {
    display: flex;
    flex-direction: column;
    background-color: #b0bcc9;
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
  }
  
  .header, .footer {
    text-align: center;
    padding: 20px;
    background-color: #66788A;
    color: #ffffff;
    /* Assuming fixed heights for header and footer for the calculation */
    height: 30px; /* Example fixed height */
  }
  
  .content-area {
    display: flex;
    flex-grow: 1; /* Allows this area to expand */
    overflow: hidden; /* Prevents any overflow from this container */
  }
  
  .sidebar {
    width: 15%;
    background-color: #8fa1b3;
    padding: 20px;
    overflow-y: auto; /* Optional: Makes sidebar scrollable if content overflows */
    height: calc(100vh - 180px); /* Adjust based on the total height of header and footer */
  }
  
  .main-content {
    flex-grow: 1;
    background-color: #d1d8e0;
    padding: 20px;
    overflow-y: auto; /* Enables vertical scrolling */
    height: calc(100vh - 180px); /* Adjust based on the total height of header and footer */
  }
  
  .sidebar, .main-content {
    height: calc(100vh - (HeaderHeight + FooterHeight + AnyExtraSpacing));
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li a {
    display: block;
    padding: 10px 20px;
    color: #ecf0f1;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .menu li a:hover, .menu li a:focus {
    background-color: #2980b9;
  }

  .menu li.active a {
    background-color: #bc861a; /* Active item color */
  }